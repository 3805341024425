import React from "react";

const BlogPost = () => {
  return (
    <div className="max-w-5xl mx-auto px-4 py-6">
      {/* Header */}
      <header className="text-center mb-8">
        <h1 className="text-4xl font-bold text-gray-900">The Irony of Technology: Saving the Planet, One App at a Time</h1>
      </header>

      <img 
          src="assets/envblog.webp" 
          alt="AI-Powered Code Assistant" 
          className="w-full h-auto rounded-lg shadow-xl mb-10"
        />

      {/* Introduction */}
      <section className="mb-8">
        <p className="text-lg text-gray-700">
          Technology and climate change: two things that seem to be at odds. One is responsible for creating a digital world we can't live without, and the other is rapidly making the world we live in a little bit harder to inhabit. From smartphones to cloud storage, and AI to electric cars, it seems like technology is both the problem and the solution.
        </p>
        <p className="text-lg text-gray-700 mt-4">
          Let’s explore the fascinating (and sometimes ironic) ways that technology impacts our environment, saving it in one instance and contributing to its downfall in the next. Buckle up, because this ride is as electrifying as a Tesla's battery.
        </p>
      </section>

      {/* Main Content */}
      <section className="space-y-12">
        {/* Carbon Footprint of Gadgets */}
        <div>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">The Carbon Footprint of Your Favorite Gadgets</h2>
          <p className="text-lg text-gray-700">
            When you pick up your phone to scroll through TikTok, do you ever stop and think, "Am I helping or hurting the planet?" Probably not. But let’s face it: your phone’s carbon footprint is a lot bigger than you'd expect. According to one study, if every person in the world upgraded their phone to a brand-new model today, it would create as much carbon as flying from Earth to the moon and back. <i>Ouch.</i>
          </p>
          <p className="text-lg text-gray-700 mt-4">
            But it’s not just the phones. It’s the entire ecosystem of gadgets we’re constantly upgrading. Laptops, tablets, smart speakers – they all require energy to build, operate, and dispose of. The average lifespan of a smartphone is about 2-3 years, meaning it’s basically designed to end up in a landfill faster than you can say “e-waste.” Add to that the millions of phones sent to the scrap heap because of a cracked screen or a slow processor, and you’ve got yourself an environmental disaster disguised as the next big upgrade.
          </p>
          <p className="text-lg text-gray-700 mt-4">
            So, before you upgrade your phone for a better camera, ask yourself, "Do I really need a new device?" The planet might thank you for it.
          </p>
        </div>

        {/* The Internet */}
        <div>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">The Internet: Running on “Invisible” Power, But Still Real Smog</h2>
          <p className="text-lg text-gray-700">
            You’ve probably heard about the cloud – that fluffy, magical place where all your data, photos, and memes live. What you might not realize is that the cloud is powered by data centers that suck up more electricity than a small country. They run 24/7, and despite being "invisible," they are creating massive amounts of emissions.
          </p>
          <p className="text-lg text-gray-700 mt-4">
            In fact, it’s estimated that the internet accounts for about 2% of global carbon emissions. That’s more than the airline industry. So, the next time you upload that cute dog video or stream your favorite Netflix show, just know you're adding to the digital smog.
          </p>
          <p className="text-lg text-gray-700 mt-4">
            Think about it: Your next Netflix binge might just be contributing more to the environment than you thought. So, next time, maybe skip the next episode.
          </p>
        </div>

        {/* Green Innovations */}
        <div>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">The Bright Side of Tech: Green Innovations</h2>

          {/* Electric Cars */}
          <h3 className="text-xl font-semibold text-gray-800 mt-8 mb-4">Electric Cars: A Glimmer of Hope (If You’re Charging Them Right)</h3>
          <p className="text-lg text-gray-700">
            Let’s talk about electric cars – the “green” technology that’s supposed to save the planet. When it comes to climate change, EVs are like a modern-day knight in shining armor, fighting off the evil fossil fuel dragon. If you’ve been living under a rock (or in the 1990s), electric vehicles (EVs) are powered by electricity rather than gasoline, and they produce fewer greenhouse gases over their lifetime than traditional cars. Even though the batteries used to power EVs are still made with materials that require mining (sometimes in environmentally sensitive areas), the overall impact is still better.
          </p>
          <p className="text-lg text-gray-700 mt-4">
            However, don’t get too excited just yet. If you’re plugging your Tesla into a grid powered by coal, you might just be driving around in a fancy carbon-emitting machine. The real win comes when you start charging your EV with renewable energy. So, until your neighborhood solar panel installation becomes as common as a Starbucks, maybe you should pedal that bike a little more.
          </p>

          {/* Renewable Energy */}
          <h3 className="text-xl font-semibold text-gray-800 mt-8 mb-4">Renewable Energy: Solar Panels on Your Roof or Your Smartphone?</h3>
          <p className="text-lg text-gray-700">
            Let’s get real: renewable energy is the true MVP in the tech world’s battle against climate change. Wind, solar, hydroelectric, and geothermal energy sources are steadily becoming more efficient, cost-effective, and available. Solar panels, for example, are now so efficient that if you have one on your roof, you could power your entire house and still have enough energy to run your Alexa.
          </p>
          <p className="text-lg text-gray-700 mt-4">
            And let’s not forget about solar-powered charging stations for electric vehicles. In the not-so-distant future, you might be able to charge your car under a canopy of solar panels while sipping your iced latte. Talk about future-proofing.
          </p>
        </div>

        {/* The Dark Side */}
        <div>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">The Dark Side: Digital Overload and E-Waste</h2>

          {/* E-Waste */}
          <h3 className="text-xl font-semibold text-gray-800 mt-8 mb-4">E-Waste: The Tech-Savvy Apocalypse</h3>
          <p className="text-lg text-gray-700">
            We’ve all heard about the growing problem of e-waste, but did you know that the world is producing more e-waste than ever before? A recent report estimated that we generate 53.6 million metric tons of e-waste annually. That’s more than the weight of the entire Great Wall of China. And unlike a regular trash heap, these tech devices don’t just disappear into the soil – they can leach toxic chemicals like lead, mercury, and cadmium into the environment.
          </p>
          <p className="text-lg text-gray-700 mt-4">
            But instead of sending your old gadgets to the landfills, you could try recycling them. Yes, it’s a thing. In fact, there are now tech companies offering trade-in programs where you can exchange your outdated phones for some shiny new gadgets. So, let’s start recycling our old tech gear – your planet (and your conscience) will thank you.
          </p>

          {/* Streaming */}
          <h3 className="text-xl font-semibold text-gray-800 mt-8 mb-4">Streaming: The Energy-Hungry Beast You Keep Feeding</h3>
          <p className="text-lg text-gray-700">
            Streaming services like Netflix, Disney+, and Spotify are all things we love to binge. But they consume energy. A lot of it. Streaming HD video, for example, consumes roughly 3.2 kWh per hour of content. That’s the same amount of energy it takes to run a refrigerator for a day. So, while you’re enjoying the latest Netflix series, think about this: you’re also contributing to the carbon footprint. Maybe it’s time to throw a good old-fashioned DVD in the player and call it a day. Who said you can’t enjoy nostalgia and help save the environment?
          </p>
        </div>

        {/* Conclusion */}
        <div>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">What’s Next for Tech and Climate?</h2>
          <p className="text-lg text-gray-700">
            So, is technology the villain or the hero in the story of climate change? Well, the truth is, it’s both. The same gadgets that make our lives easier are also creating some of the biggest environmental challenges. But the key is finding ways to balance innovation with sustainability. With more green tech initiatives on the horizon, we may just find a way to power the future without destroying the planet in the process.
          </p>
        </div>
      </section>

    </div>
  );
};

export default BlogPost;
